<template>
  <div v-if="loading" class="loader-container">
    <loader></loader>
  </div>
  <div id="app" v-else>
    <div id="wrapper" :class="{ toggled: sidebarShown }">
      <div class="overlay" :class="{ block: sidebarShown }"></div>
      <sidebar v-if="menu && !$route.hideSidebar && sidebarShown"></sidebar>
      <div class="app main-panel" :id="company ? 'page-content-wrapper' : 'error-content-wrapper'" v-if="!loading">
        <navbar v-if="company" />
        <router-view :key="$route.fullPath"></router-view>
        <scroll-top></scroll-top>
      </div>
      <app-add-to-homescreen></app-add-to-homescreen>
      <app-footer v-if="company"></app-footer>
      <keep-alive></keep-alive>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { updateLanguage } from '@shared/translations';

import theme from '@shared/mixins/theme';

const Navbar = () => import('./components/ui/Navbar');
const Sidebar = () => import('./components/ui/Sidebar');
const Footer = () => import('./components/ui/Footer');
const AddToHomescreen = () => import('./components/utils/AddToHomeScreen');
const KeepAlive = () => import('./components/keepAlive');

export default {
  name: 'app',
  mixins: [theme],
  components: {
    appFooter: Footer,
    appAddToHomescreen: AddToHomescreen,
    Navbar,
    Sidebar,
    KeepAlive
  },
  created() {
    this.$store.dispatch('settings/getCompanyData').then(
      (res) => {
        this.loading = false;
        const { faviconURL } = res.data;
        if (faviconURL) {
          this.setFavicon('#apple-touch-icon', faviconURL);
          this.setFavicon('#apple-touch-icon-152', faviconURL);
          this.setFavicon('#ms-icon', faviconURL);

          let icons = document.querySelectorAll('link[rel=icon]').forEach((icon) => {
            icon.setAttribute('href', faviconURL);
          });
        }

        let onboardingPageName = window.localStorage.onboardingPageName;

        if (onboardingPageName) {
          document.title = window.localStorage.onboardingPageName;
        }

        const browserLanguage = navigator.language || navigator.userLanguage;
        const { languages } = res.data;
        const defaultLanguage = languages.filter((language) => language.default)[0];

        let languageToSet = 'en-US';
        if (this.user && this.user.language) {
          languageToSet = this.user.language;
        } else if (defaultLanguage) {
          languageToSet = defaultLanguage.localeCode;
        } else if (browserLanguage) {
          const languageMatch = languages.filter((language) => {
            return (
              language.localeCode.toLowerCase() === browserLanguage.toLowerCase() ||
              language.localeCode.substring(0, language.localeCode.indexOf('-')).toLowerCase() ===
                browserLanguage.substring(0, 2).toLowerCase()
            );
          });
          if (languageMatch && languageMatch.length) {
            languageToSet = languageMatch[0].localeCode;
          } else {
            const defaultLanguage = languages.filter((language) => language.default);
            if (defaultLanguage && defaultLanguage[0]) {
              languageToSet = defaultLanguage[0].localeCode;
            }
          }
        } else if (languages.length === 1) {
          languageToSet = languages[0].localeCode;
        }

        if (languageToSet && window.localStorage.defaultLanguage !== languageToSet) {
          window.localStorage.defaultLanguage = languageToSet;
          updateLanguage(languageToSet);
        }

        if (defaultLanguage) {
          this.$store.commit('learningPages/setDefaultLocaleCode', defaultLanguage.localeCode);
        }
      },
      (err) => {
        this.loading = false;
        if (window.matchMedia('(display-mode: standalone)').matches) {
          return this.$router.push('/503');
        }

        this.$router.push('/404');
      }
    );
  },
  methods: {
    setFavicon(id, faviconURL) {
      document.querySelector(id).setAttribute('href', faviconURL);
    }
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters('settings', ['company', 'sidebarShown', 'subdomain']),
    ...mapGetters('dashboard', ['menu']),
    ...mapGetters('auth', ['authenticated', 'user'])
  }
};
</script>

<style lang="scss">
@import '@/shared/styles/all.scss';
@import './app.scss';
</style>
